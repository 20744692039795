<template>
  <div>
    <div class="pa-3">
      <div class="overflow-y-auto overflow-x-hidden">
        <dialog-confirm
          :open="openDialog"
          @close="closeDialog"
        ></dialog-confirm>
        <div class="d-flex justify-center">
          <div class="col-12 col-md-5 col-lg-3">
            <div class="d-flex flex-column">
              <h2 class="justify-center">Visitante</h2>
              <p class="my-0">
                En este espacio encontrará la información del visitante
                registrado en el evento
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-center">
          <div class="col-12 col-md-5 col-lg-3">
            <v-card elevation="6" class="pa-4 rounded-lg" color="#F8F8F8">
              <div>
                <div class="col-sm-12 pa-0">
                  <h3 class="my-0">Información QR</h3>
                </div>
                <div class="col-sm-12 d-flex d-inline pa-0">
                  <label class="mr-2 text-info-qr">Nombre: </label>
                  <label class="text-info-qr" v-text="visitor.fullName"></label>
                </div>
                <div class="col-sm-12 d-flex d-inline pa-0">
                  <label class="mr-2 text-info-qr">Empresa: </label>
                  <label
                    class="text-info-qr"
                    v-text="visitor.companyName"
                  ></label>
                </div>
                <div class="col-sm-12 d-flex d-inline pa-0">
                  <label class="mr-2 text-info-qr">Cargo: </label>
                  <label
                    class="text-info-qr"
                    v-text="visitor.companyPosition"
                  ></label>
                </div>
                <div class="col-sm-12 d-flex d-inline pa-0">
                  <label class="mr-2 text-info-qr">Celular: </label>
                  <label class="text-info-qr" v-text="visitor.phone"></label>
                </div>
                <div class="col-sm-12 d-flex d-inline pa-0">
                  <label class="mr-2 text-info-qr">Correo: </label>
                  <label class="text-info-qr" v-text="visitor.email"></label>
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <div class="d-flex justify-center">
          <div class="col-12 col-md-5 col-lg-3">
            <v-card class="py-4 px-4 rounded-lg" elevation="6" color="#F8F8F8">
              <div class="row justify-center">
                <div class="col-auto px-4">
                  <v-img
                    v-if="imageUrl"
                    :src="imageUrl"
                    @error="isErrorOnLoadVisitorImage = true"
                    max-height="115"
                    contain
                  />
                  <div v-else class="col d-flex justify-center grey--text">
                    <i class="fa fa-4x fa-camera mx-auto"></i>
                  </div>
                </div>
                <div class="col-sm-12 px-4">
                  <input
                    type="file"
                    ref="photo"
                    accept="image/*"
                    capture="camera"
                    class="d-none"
                    @change="setPhoto"
                  />
                  <v-btn
                    outlined
                    class="w-100 black--text border-1"
                    shaped
                    @click="$refs.photo.click()"
                    >Tomar y actualizar foto
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <div class="d-flex justify-center">
          <div class="col-12 col-md-5 col-lg-3">
            <v-card class="pa-4 rounded-lg" elevation="6" color="#F8F8F8">
              <div>
                <div class="col-sm-12 pa-0">
                  <h3 class="font-weight-bold my-0">Descripción</h3>
                  <v-textarea
                    :maxlength="maxCharacteresDescription"
                    hide-details
                    v-model="visitor.description"
                    class="my-0 py-0 text-info-qr"
                    placeholder="Agrega aquí tus comentarios."
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div class="pa-3 action-buttons">
      <div class="d-flex justify-center">
        <div class="col-12 col-md-5 col-lg-3">
          <div class="d-flex flex-column">
            <v-btn
              class="w-100 rounded-lg text-capitalize"
              elevation="2"
              @click="save"
              color="primary"
            >
              <v-icon class="mr-2">mdi-autorenew</v-icon> Actualizar
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.w-100 {
  min-width: 100% !important;
  width: 100% !important;
}

.text-info-qr {
  font-weight: 400;
  font-size: 13px;
  color: #808080;
}

.action-buttons {
  position: fixed;
  bottom: 56px;
  width: 100%;
  background: white;
}
</style>

<script>
import DialogConfirm from "./components/DialogConfirm";
import { BASE_IMAGES_URL } from "@/constants/ServicesConstants";
import loaderMixin from "@/mixins/loaderMixin";
import notificationMixin from "@/mixins/notificationMixin";
import Compressor from "compressorjs";
import eventHub from "@/eventhub";

export default {
  name: "DetailVisitor",
  components: {
    DialogConfirm,
  },
  data() {
    return {
      maxCharacteresDescription: 255,
      openDialog: false,
      changePhoto: false,
      imageInBase64: null,
      defaultImage: require("@/assets/images/visitors/visitor-default.png"),
      isErrorOnLoadVisitorImage: false,
      description: null,
    };
  },
  methods: {
    closeDialog(value) {
      this.openDialog = value;
    },
    getData() {
      return {
        ...this.visitor,
        imageInBase64: this.imageInBase64,
      };
    },
    setPhoto(e) {
      const [file] = e.target.files;
      if (file) {
        new Compressor(file, {
          quality: 0.6,
          success: (result) => {
            const reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onload = () => {
              this.imageInBase64 = reader.result;
            };
          },
          error: (err) => {
            console.info(err);
          },
        });
      }
    },
    async save() {
      this.showLoader();
      let response = await this.$store.dispatch(
        "visitors/saveVisitor",
        this.getData()
      );
      this.hideLoader();
      if (response?.status) {
        if (response.isOffline) {
          this.showSuccess(
            "Visitante actualizado, recuerda periodicamente sincronizar tus visitantes"
          );
        } else {
          this.showSuccess("Visitante actualizado");
        }
        this.$router.push({ name: "visitors" });
      } else {
        this.showError("Error al actualizar visitante");
      }
    },
  },
  computed: {
    visitor() {
      return this.$store.getters["visitors/tempVisitor"] ?? {};
    },
    isModified() {
      return this.visitor.description != this.description || this.imageInBase64;
    },
    imageUrl() {
      if (this.imageInBase64) {
        return this.imageInBase64;
      }

      if (this.isErrorOnLoadVisitorImage) {
        return this.defaultImage;
      } else {
        if (this.visitor.imageUrl) {
          return `${BASE_IMAGES_URL}${this.visitor.imageUrl}`;
        } else if (this.visitor.imageInBase64) {
          return this.visitor.imageInBase64;
        } else {
          return this.defaultImage;
        }
      }
    },
  },
  created() {
    if (!this.visitor?.attendeeUuid) {
      this.$router.push({ name: "visitors" });
      return;
    }

    this.description = this.visitor.description;
    this.imageInBase64 = this.visitor.imageInBase64;

    eventHub.$on("showDialogVisitor", () => {
      this.openDialog = true;
    });
  },
  mixins: [loaderMixin, notificationMixin],
};
</script>
